import { SELECT_LANGUAGE } from '@/constants/question-type'
import { getSurvey } from '@/api/response'

export const PreviewMixin = {
  data () {
    return {
      error: null,
      changes: {},
      survey: null,
    }
  },
  computed: {
    langFromQuestion () {
      const selectLangQuestion = this.questions?.find(question => question?.type === SELECT_LANGUAGE)
      return this.changes[selectLangQuestion?.qid]
    },
    lang () {
      return this.langFromQuestion || this.$route.query?.lang || this.survey?.default_language
    },
    showQuestionCode () {
      return this.survey?.show_question_code
    },
  },
  methods: {
    onChange (data) {
      this.changes = { ...this.changes, ...data }
    },
    async getSurvey () {
      const { params: { surveyCode }, query: { lang } } = this.$route
      const { data: { data: survey } } = await getSurvey(surveyCode)
      this.survey = survey
      if (lang && !survey?.supported_languages.includes(lang)) {
        this.error = {
          code: 400,
          message: this.$t('errors.LANGUAGE_NOT_SUPPORTED')
        }
      }
    }
  }
}
