export const ErrorHandlerMixin = {
  methods: {
    errorHandler (err) {
      const message = err?.response?.data?.message || err.message
      this.error = {
        code: err?.response?.data?.error_code || 500,
        message: this.$t(`errors.${message}`) || message
      }
    },
  }
}
