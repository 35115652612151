export const QuestionTypeComponentsMixin = {
  components: {
    ErrorMessage: () => import('@/components/ErrorMessage'),
    Policy: () => import('@/components/Policy'),
    Reward: () => import('@/components/Reward'),
    SandboxHtml: () => import('@/components/SandboxHtml'),
    QSL: () => import('@/components/SelectLanguage'),
    QSA: () => import('@/components/SingleAnswer'),
    QMA: () => import('@/components/MultipleAnswer'),
    QFA: () => import('@/components/FreeAnswer'),
    QNI: () => import('@/components/NumberInput'),
    QTD: () => import('@/components/TextDisplay'),
    QSAM: () => import('@/components/SingleAnswerMatrix'),
    QDL: () => import('@/components/DropdownList'),
    QS: () => import('@/components/SingleAnswer'),
    QDT: () => import('@/components/DateTimePicker'),
  }
}
