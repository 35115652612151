<template>
  <div class="preview-survey">
    <template v-if="!error && question">
      <section class="section">
        <div class="columns is-centered is-vcentered">
          <component
              :is="`Q${question.type}`"
              :question="question"
              :lang="lang"
              :value="changes"
              @input="onChange"
          ></component>
        </div>
      </section>
    </template>
    <ErrorMessage :error="error"></ErrorMessage>
  </div>
</template>

<script>
import { getSurveyQuestion } from '@/api/response'
import { QuestionTypeComponentsMixin } from '@/mixins/question-type-components'
import { ErrorHandlerMixin } from '@/mixins/error-handler'
import { LocaleSwitchingMixin } from '@/mixins/locale-switching'
import { PreviewMixin } from '@/mixins/preview'

export default {
  name: 'PreviewQuestion',
  mixins: [
    QuestionTypeComponentsMixin,
    ErrorHandlerMixin,
    LocaleSwitchingMixin,
    PreviewMixin,
  ],
  data () {
    return {
      question: null,
    }
  },
  methods: {
    async init () {
      this.error = null
      const loadingComponent = this.$buefy.loading.open()
      try {
        const { params: { surveyCode, questionCode } } = this.$route
        if (surveyCode && questionCode) {
          await this.getSurvey()
          const { data: { data } } = await getSurveyQuestion(surveyCode, questionCode)
          this.question = data
        }
      } catch (error) {
        this.errorHandler(error)
      } finally {
        loadingComponent.close()
      }
    },
  },
  created () {
    this.init()
  }
}
</script>
